import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import translationHelper from "../i18n/helper"
import parse from "html-react-parser"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import SectionTitleVerticalSeperator
  from "../components/Misc/SectionTitleVerticalSeperator/SectionTitleVerticalSeperator"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import CallToAction from "../components/CallToAction/callToAction"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import GradientText from "../components/Misc/GradientText/gradientText"
import PortfolioSeeProjectBtn from "../components/Portfolio/PortfolioSeeProjectBtn/portfolioSeeProjectBtn"
import PortfolioNextPrev from "../components/Portfolio/PortfolioNextPrev/portfolioNextPrev"
import localeConfig from "../constants/locales"
class SingleProject extends Component {
  render() {

    const project = this.props.data.wordpressWpProjects
    const projectMedia = this.props.data.wordpressWpMedia
    const projectCategories = this.props.data.wordpressWpProjectCategories
    const locale = this.props.pageContext.locale
    const techIcons = this.props.data.techIcons.edges
    const nextItem = this.props.data.next
    const prevItem = this.props.data.prev

    const localePrefix = !localeConfig[locale] || localeConfig[locale].default ? "" : localeConfig[locale].urlPrefix+"/"



    return (
      <Layout locale={this.props.pageContext.locale} path={this.props.path}>

        <SEO
          title={project.title}
          lang={this.props.pageContext.locale}
          description={project.yoast_meta.yoast_wpseo_metadesc ? project.yoast_meta.yoast_wpseo_metadesc : null}
          image={project.yoast_meta.yoast_wpseo_facebook_image ? project.yoast_meta.yoast_wpseo_facebook_image : project.featured_media.localFile.childImageSharp.fixed.src}
        />
        <Container>
          <Row>
            <Col sm={12} className="text-center">
              <EmptySpaceSeperator pixels={40}/>
              <h1 className="mb-4 h1ShadowBig">{parse(project.title)}</h1>
              <Row className="mb-2">
                <Col sm={3}/>
                <Col sm={6}>

                  {
                    projectCategories && (
                      <SectionTitleVerticalSeperator title={parse(projectCategories.name)}/>
                    )
                  }


                </Col>
                <Col sm={3}/>
              </Row>
              {
                projectMedia != null && projectMedia.localFile != null ?
                  <Img alt={projectMedia.alt_text || null} fluid={projectMedia.localFile.childImageSharp.fluid}/> : null
              }

            </Col>
          </Row>
          <Row>
            {project.acf.iframe_url ?
              // Virtual Tour Layout
              <Col sm={12}>
                <SectionSingleVerticalSeperator classes="mb-4"/>
                <BoxedItem>
                  <div className="p-4">
                    <SectionTitleVerticalSeperator title={translationHelper[locale]["projectDescription"]}/>
                    <Row className="mb-2">
                      <Col sm={12}>
                        <div className="text-left">
                          {parse(project.acf.project_description)}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </BoxedItem>
                <SectionSingleVerticalSeperator classes="mb-4 mt-4"/>


                <iframe title={project.title} src={project.acf.iframe_url} width="100%" height="600"/>
              </Col>
              // If it's a Website Or Eshop
              : <Col sm={12}>
                <SectionSingleVerticalSeperator classes="mb-4"/>
                <Row>
                  <Col md={project.acf.workflow ? 6 : 12} className="mb-4">
                    <BoxedItem className="eqHeightGlobal">

                      <div className="p-4">
                        <SectionTitleVerticalSeperator title={translationHelper[locale]["projectDescription"]}/>
                        <Row className="mb-2">
                          <Col sm={12}>
                            <div className="text-left">
                              {project.acf.project_description ? parse(project.acf.project_description) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </BoxedItem>
                  </Col>
                  {project.acf.workflow ?
                    <Col md={6} className="mb-4">
                      <BoxedItem className="eqHeightGlobal">

                        <div className="p-4">
                          <SectionTitleVerticalSeperator title={translationHelper[locale]["projectWorkflow"]}/>
                          <Row className="mb-2">
                            <Col sm={12}>
                              <div className="text-left">
                                {parse(project.acf.workflow)}
                              </div>
                            </Col>
                          </Row>
                        </div>

                      </BoxedItem>
                    </Col>
                    : null}

                </Row>
                <EmptySpaceSeperator/>

                <Row>
                  {
                    techIcons.map((tech) => {
                      return (
                        <Col key={tech.node.id} md={2} sm={4} xs={6}>
                          <BoxedItem style={{ borderRadius: "4px", marginBottom: "20px" }}>
                            <div className="p-4">
                              <Img alt={tech.node.alt_text || null} style={{ maxWidth: "50%", transform: "translateX(-50%)", left: "50%" }}
                                   fluid={tech.node.localFile.childImageSharp.fluid}/>
                            </div>
                            <div className="text-center">
                              <GradientText>{tech.node.title}</GradientText>
                            </div>

                          </BoxedItem>
                        </Col>
                      )
                    })
                  }
                </Row>
                <SectionSingleVerticalSeperator classes="mb-4 mt-4"/>

                <PortfolioSeeProjectBtn
                  url={project.acf.website_link}
                  btnText={projectCategories.slug === "istoselida" ? "See the" : "See the" + (projectCategories.name !== "Custom Solutions" ? projectCategories.name : "Project")}
                />
                <EmptySpaceSeperator/>

              </Col>

            }
          </Row>
        </Container>
        <EmptySpaceSeperator/>
        <Container fluid={true} className="p-0">
          <Row noGutters={true}>
            <Col sm={12}>
              <CallToAction
                heading={translationHelper[locale]["askForOffer"]}
                subheading={translationHelper[locale]["forYourProject"]}
                url={translationHelper[locale]["offerPageUrl"]}
                icon="long-arrow-alt-right"
                btnText={translationHelper[locale]["letStart"]}
              />
              <EmptySpaceSeperator pixels={40}/>
              <PortfolioNextPrev
                next={!!nextItem}
                nextUrl={nextItem ? `${localePrefix}/portfolio/${nextItem.slug}` : null}
                previous={!!prevItem}
                prevUrl={prevItem ? `${localePrefix}/portfolio/${prevItem.slug}` : null}
              />
              <EmptySpaceSeperator pixels={40}/>

            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

SingleProject.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default SingleProject

export const pageQuery = graphql`
    query($slugUnEscaped: String!,$locale: String,$next: String, $prev: String,$devicesImageId: Int,$categories: [Int],$technologyIcons:[Int]) {
        wordpressWpProjects(slug: { eq: $slugUnEscaped },polylang_current_lang: {eq: $locale}) {
            wordpress_id
            title
            content
            featured_media {
                alt_text
                localFile {
                    childImageSharp{
                        fixed(width:1200) {
                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                        }
                    }

                }
            }
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_facebook_image {
                    localFile {
                        childImageSharp {
                            fixed{
                                src
                            }
                        }
                    }
                }
                yoast_wpseo_facebook_title
                yoast_wpseo_canonical
            }
            project_categories
            slug
            path
            acf {
                iframe_url
                project_description
                display_image {
                    wordpress_id
                }
                technology_icons {
                    wordpress_id
                }
                workflow
                website_link
            }
        }
        wordpressWpMedia(wordpress_id: {eq: $devicesImageId}) {
            id
            alt_text
            localFile {
                childImageSharp {
                    fluid(maxWidth:4200) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }

                }
            }
        }
        techIcons: allWordpressWpMedia(filter: {wordpress_id: {in: $technologyIcons}}) {
            edges {
                node {
                    id
                    alt_text
                    localFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                    title
                }
            }
        }
        next: wordpressWpProjects(slug: {eq:$next}) {
            featured_media {
                alt_text
                localFile {
                    childImageSharp {
                        fluid(maxWidth:600) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            slug
            path
            title
        }
        prev: wordpressWpProjects(slug: {eq:$prev}) {
            featured_media {
                alt_text
                localFile {
                    childImageSharp {
                        fluid(maxWidth:600) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            slug
            path
            title
        }
        wordpressWpProjectCategories(wordpress_id: {in: $categories}) {
            name
            path
            slug
        }

    }
`
