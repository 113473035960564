import React  from "react"
import classes from "./portfolioNextPrev.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faTh, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"
 import { Link } from "gatsby"
import LocalizedLink from "../../LocalizedLink"
import { injectIntl } from "react-intl"
import localeConfig from "../../../constants/locales"



const portfolioNextPrev = (props) => {
  const { intl: { locale } } = props

  const localePrefix = !localeConfig[locale] || localeConfig[locale].default ? "" : localeConfig[locale].urlPrefix

  library.add(faTh)
  return (
    <div>
      <Container>
        <Row>
          <Col sm={5} className={classes.Previous}>
            {
              props.previous ?
                <LocalizedLink className={classes.Link} to={props.prevUrl}>
                  < FontAwesomeIcon size="1x" icon={faArrowLeft}/>
                  <span>{props.intl.formatMessage({ id: "prevProject" })}</span>
                  </LocalizedLink>

                : null
            }
          </Col>
          <Col sm={2} className={classes.AllItems}>
            <Link style={{color:"#000"}} to={localePrefix+"/portfolio"}>
            <FontAwesomeIcon size="lg" icon={faTh}/>
            </Link>
          </Col>
          <Col sm={5} className={classes.Next}>
            {
              props.next ?
                <LocalizedLink className={classes.Link} to={props.nextUrl}>
                  <span>{props.intl.formatMessage({ id: "nextProject" })}</span>
                  < FontAwesomeIcon size="1x" icon={faArrowRight}/></LocalizedLink>

                : null
            }
          </Col>
        </Row>
      </Container>
    </div>
  )
}
portfolioNextPrev.propTypes = {
  next: PropTypes.bool.isRequired,
  previous: PropTypes.bool.isRequired,
}
export default injectIntl(portfolioNextPrev)
